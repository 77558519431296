<template>
    <div class="copyright">© {{ text }}</div>
</template>

<script>
    export default {
        name: 'Copyright',

        props: [
            'text'
        ]
    }
</script>

<style lang="scss" scoped>
    .copyright {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.6;
        line-height: 20px;
        font-style: getFontStyle('copyright');
        font-size: getFontSize('copyright', 'desktop');
        font-weight: getFontWeight('copyright');
        padding: 15px 50px;
        color: rgba(255,255,255,0.75);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background: darken($timberGreen, 10);
            transform-origin: 100% 0;
            transform: skew(-20deg);
            z-index: -1;
        }

        @include breakpoint('tablet') {
            font-size: getFontSize('copyright', 'tablet');
            padding: 10px 20px;
        }
        @include breakpoint('mobile') {
            font-size: getFontSize('copyright', 'mobile');
            padding: 2px 10px;
        }
    }
</style>
